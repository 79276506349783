
















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';
import BaseModal from './BaseModal.vue';
import BaseTextArea from '../core/BaseTextArea.vue';

@Component({
  name: 'DeletionConfirmation',
  components: {
    BaseModal,
    BaseButton,
    BaseTextArea
  }
})
export default class DeletionConfirmation extends Vue {
  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: false }) readonly inProgress!: boolean;

  @Prop({ default: '' }) readonly title!: string;

  @Prop({ default: '' }) readonly description!: string;
}
